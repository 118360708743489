import { Fragment           } from 'react'
import CheckIcon              from '../../icons/actions/CheckIcon'
import KeyboardArrowRightIcon from '../../icons/arrows/KeyboardArrowRightIcon'
import './checkoutSteps.css'

const CheckoutSteps = ({ steps, activeStep }) => {
   
    return (

        <div className="stepper-container">

            <div className="stepper">

                {steps.map((step, index) => {

                    const isActive = activeStep === index
                    const isComplete = activeStep > index

                    return (
                        <Fragment key={step.label}>
                        
                            <div>                            

                                <span className={`step ${isActive ? 'active' : isComplete ? 'complete' : ''}`}>
                                    {isComplete ? <CheckIcon /> : index + 1}
                                </span>

                                <small>{step.label}</small>                           

                            </div>

                            {index < steps.length - 1 && 
                                <>
                                <span className="line" />
                                <KeyboardArrowRightIcon />
                                </>
                            }

                        </Fragment>

                    )
                })}
            </div>

        </div>

    )

}

export default CheckoutSteps