import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { formatPrice } from '../../../utils'
import CheckIcon from '../../layout/icons/actions/CheckIcon'
import ArrowBackIcon from '../../layout/icons/arrows/ArrowBackIcon'
import CheckoutSteps from '../../layout/tools/checkoutSteps/CheckoutSteps'

const steps = [
    { label: 'Shipping' },
    { label: 'Confirm' },
    { label: 'Payment' }
]

const ConfirmOrder = ({ setIsShippingOpen, setIsConfirmOpen, setIsStripeOpen }) => {

    const { merchCartItems, shippingInfo } = useSelector( state => state.merchCart )

    const proceedToPayment = () => {  
          
        const data = {
            itemsPrice: itemsPrice,
            shippingPrice,
            totalPrice
        }
        sessionStorage.setItem('orderInfo', JSON.stringify(data))
        setIsConfirmOpen(false)  
        setIsStripeOpen(true)
    }

    const handleBack = () => {        
        setIsConfirmOpen(false)
        setIsShippingOpen(true)  
    }

    const calculateTotalShipping = () => {

        let shippingCost = 0
      
        merchCartItems.forEach((item) => {
          
            shippingCost += item.shipping + (item.shippingTwo * (item.quantity - 1))
          
        })      
        return shippingCost
    }

    const calcSubTotal = () => {
        let subTotal = 0      
        merchCartItems.forEach((item) => {
            subTotal += item.price * item.quantity          
        })  
        return subTotal
    }

    const calcTotal = () => {
        let total = 0    
        merchCartItems.forEach((item) => {          
            total += item.price * item.quantity           
        })  
        return total + shippingPrice
    }

    // Calculate Order Prices
    const itemsPrice    = calcSubTotal()    
    const shippingPrice = calculateTotalShipping()
    const totalPrice    = calcTotal()
   
    return (   
        <>
        <button 
            className="icon-button modal-back-button" 
            aria-label="back"
            onClick={handleBack}
            style={{ margin: '5px 0 0 5px' }}
        >

            <ArrowBackIcon />

        </button> 

        <h3>Confirm</h3>  

        <CheckoutSteps steps={steps} activeStep={1} />

        <div>  

            <table className="cart-table" style={{ marginBottom: 40 }}>
                <tbody>
                {merchCartItems && merchCartItems.map((item, index) => (
                        <tr key={index}>
                            <th className="text-center">
                                <Link to={`/product/${item.slug}`}>
                                    <img
                                        src={item.image} 
                                        alt={item.name} 
                                        style={{ width: 50 }}
                                    />
                                    <br />   
                                    <b><small>{item.name}</small></b>
                                </Link>
                            </th>
                            <td className="text-right">{item.quantity}</td> 
                        </tr>
                    ))}
                    <tr>
                        <th 
                            className="text-right" 
                            style={{ width: 120 }}
                        >
                            Subtotal
                        </th>
                        <td className="text-right">
                            {formatPrice(itemsPrice)}
                        </td>    
                    </tr>
                    <tr>
                        <th className="text-right">
                            Shipping
                        </th>   
                        <td className="text-right">
                            {formatPrice(shippingPrice)}
                        </td>    
                    </tr>                    
                    <tr>
                        <th className="text-right">                           
                            Total
                        </th>                           
                        <td className="text-right">
                            {formatPrice(totalPrice)}
                        </td>
                    </tr>
                </tbody>

            </table>    

            <table className="cart-table">
                <tbody>                                                   
                    <tr>
                        <th className="text-right" style={{ width: 120 }}>Name</th>
                        <td className="text-right">{shippingInfo.name}</td>
                    </tr>
                    
                    <tr>
                        <th className="text-right" style={{ verticalAlign: 'top' }}>Address</th>
                        <td className="text-right">
                            {shippingInfo.address}
                            <br />
                            {shippingInfo.city}
                            <br />
                            {shippingInfo.subDistrict}
                            <br />
                            {shippingInfo.district}
                            <br />
                            {shippingInfo.postalCode.toUpperCase()}
                            <br />                            
                            {shippingInfo.country}
                        </td>
                    </tr>  

                    <tr>
                        <th className="text-right">Phone</th>
                        <td className="text-right">
                            {shippingInfo.phoneNo}
                            <img
                                alt={shippingInfo.country} 
                                src={`https://flagcdn.com/${shippingInfo.countryCode}.svg`}
                                style={{ width: '20px', marginLeft: '10px' }}
                            />
                            
                        </td>
                    </tr> 
                </tbody>
            </table>             

            <button
                className="button"
                onClick={proceedToPayment}
                style={{ marginTop: 40 }}
            >
                <CheckIcon style={{ color: 'white' }} /> 
                &nbsp;
                Confirm
            </button>
            
        </div>
        </>  
    )

}

export default ConfirmOrder