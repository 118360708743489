import { useState } from 'react'
import Backdrop from '../tools/Backdrop'
import ArrowDropDownIcon from '../icons/arrows/ArrowDropDownIcon'
import ArrowDropUpIcon from '../icons/arrows/ArrowDropUpIcon'
import countryList from './countryList'
import './forms.css'

const CountrySelect = ({ required, item, onChange, filled, country, setCountry, countryCode, setCountryCode }) => {

    const countriesList = Object.values( countryList )

    const [ isOpen,      setIsOpen      ] = useState(false)
    const [ searchValue, setSearchValue ] = useState('')   

    const handleSelectChange = (e) => {
        if (item) {
            onChange(item, e.target[0].value)
            onChange('countryCode', e.target[1].value)
        } else {
            setCountry(e.target[0].value)
            setCountryCode(e.target[1].value)            
        }  
        setIsOpen(false)  
    }

    const toggleDropdown = () => {
        setIsOpen(!isOpen)
        setSearchValue('')
    } 

    const handleInputChange = (e) => {
        setSearchValue(e.target.value)
    } 

    const filteredData = countriesList.filter((item) =>
        item.name && item.name.toLowerCase().includes(searchValue.toLowerCase())
    ) 

    return (
        <>
        {isOpen && (
            <Backdrop
                invisible
                onClick={toggleDropdown}
            /> 
        )}

        <div className="select-container" style={{ zIndex: 99 }}>
            <div 
                className={`label ${isOpen ? 'open' : ''} relative`} 
                onClick={toggleDropdown}
            >
                {country ? (
                    <div 
                        tabIndex={0} 
                        className="input focusable-div cursor-pointer"
                        style={{
                            background: required 
                                ? filled
                                    ? 'var(--cta-discount)'
                                    : 'var(--cta-required)'
                                : 'var(--input-background-color)'
                        }}
                    >
                        <img
                            alt={country} 
                            src={`https://flagcdn.com/${countryCode}.svg`}
                            style={{ width: '20px', marginRight: '10px' }}
                        />
                        {country}
                    </div>
                ) : (
                    <input                        
                        className="input cursor-pointer"
                        placeholder="Nationality *"
                        value={searchValue}
                        onChange={handleInputChange}
                        style={{ background: required ? 'var(--cta-required)' : 'var(--input-background-color)' }}
                    />                   
                )} 

                <small>Country</small>
                
                <span className="adornment-end">
                   
                    {isOpen 
                        ? <ArrowDropUpIcon /> 
                        : <ArrowDropDownIcon />                            
                    }

                </span>

            </div>

            {isOpen && (                
                <>
                <ul className="select-dropdown modal-input fullHeight">
                    {country && (
                        <li>
                            <input
                                className="input"
                                placeholder="Search..."
                                value={searchValue}
                                onChange={handleInputChange}
                            />
                        </li>
                    )}                        
                    {filteredData.map(c => (  
                        <li 
                            key={c.name} 
                            onClick={() => handleSelectChange({ target: [{ value: c.name }, { value: c.code.toLowerCase() }] })}
                        >
                            <img
                                alt={c.name} 
                                src={`https://flagcdn.com/${c.code.toLowerCase()}.svg`}
                                style={{ width: '20px', marginRight: '10px' }}
                            />                                    
                            {c.name} 
                        </li>                                  
                    ))}  
                </ul>
                </>
            )}
        </div>
        </>
    )

}

export default CountrySelect