import {     
    NEW_TRIP_DETAILS_REQUEST,
    NEW_TRIP_DETAILS_RESET,
    NEW_TRIP_DETAILS_SUCCESS,    
    NEW_TRIP_DETAILS_FAIL,
    UPDATE_TRIP_DETAILS_REQUEST,
    UPDATE_TRIP_DETAILS_SUCCESS,
    UPDATE_TRIP_DETAILS_RESET,    
    UPDATE_TRIP_DETAILS_FAIL,    
    DELETE_TRIP_DETAILS_REQUEST,
    DELETE_TRIP_DETAILS_SUCCESS,
    DELETE_TRIP_DETAILS_RESET,
    DELETE_TRIP_DETAILS_FAIL,    
    TRIP_DETAILS_REQUEST,
    TRIP_DETAILS_SUCCESS,
    TRIP_DETAILS_FAIL,    
    CLEAR_ERRORS 
} from '../constants/tripDetailsConstants'

export const newTripDetailsReducer = ( state = { tripDetails: {} }, action ) => {
    switch (action.type) {

        case NEW_TRIP_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case NEW_TRIP_DETAILS_SUCCESS:
            return {
                loading: false,
                success: action.payload.success,
                tripDetails: action.payload.tripDetails
            }
        case NEW_TRIP_DETAILS_FAIL:
                return {
                    ...state,
                    error: action.payload,
                    loading: false,
                }
        case NEW_TRIP_DETAILS_RESET:
            return {
                ...state,
                success: false
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}

export const tripDetailReducer = ( state = {}, action ) => {
    switch (action.type) {

        case DELETE_TRIP_DETAILS_REQUEST:
        case UPDATE_TRIP_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }   
        case DELETE_TRIP_DETAILS_SUCCESS:        
            return {
                ...state,
                loading: false,
                isDeleted: action.payload
            }   
        case UPDATE_TRIP_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                isUpdated: action.payload
            }     
        
        case DELETE_TRIP_DETAILS_FAIL:       
        case UPDATE_TRIP_DETAILS_FAIL:
                return {
                    ...state,
                    error: action.payload
                }
        case DELETE_TRIP_DETAILS_RESET:
            return {
                ...state,
                isDeleted: false
            }
        case UPDATE_TRIP_DETAILS_RESET:
            return {
                ...state,
                isUpdated: false
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}

export const tripDetailsReducer = ( state = { tripDetails: {} }, action ) => {
    switch (action.type) {

        case TRIP_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case TRIP_DETAILS_SUCCESS:
            return {
                loading: false,
                tripDetails: action.payload
            }
        case TRIP_DETAILS_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}