import { useEffect           } from 'react'
import { useSpring, animated } from '@react-spring/web'
import ReactDOM                from 'react-dom'
import IconButton              from '../../layout/tools/iconButton/IconButton'
import ClearIcon               from '../../layout/icons/actions/ClearIcon'
import Backdrop                from '../../layout/tools/Backdrop'
import './modal.css'

function Modal({ isModalVisible, onBackdropClick, content }) {

    const animation = useSpring({           
      opacity:   isModalVisible ? 1 : 0,
      transform: isModalVisible ? 'translateX(0)' : 'translateX(100%)'        
    })  

    useEffect(() => { 
      document.body.style.overflow = isModalVisible ? 'hidden' : ''  
      return () => {
        document.body.style.overflow = ''
      }
    }, [isModalVisible])   

    if(!isModalVisible) return null

    return ReactDOM.createPortal( 
      <>
      <Backdrop onClick={onBackdropClick} />      

      <animated.div className="modal" style={animation}>         

        <div className="modal-header">
          <IconButton
            tooltip="close"
            aria="close"
            icon={<ClearIcon />}
            onClick={onBackdropClick}
          /> 
        </div>

        <div className="modal-content">
          {content}          
        </div>      
        
      </animated.div>
      
      </>,
      document.getElementById('modal-root')    
    )
}

export default Modal
