import { NavLink } from "react-router-dom"
import ArrowBackIcon from "../../layout/icons/arrows/ArrowBackIcon"

const Boats = ({ setIsNavVisible, setIsBoatsVisible, boats }) => {

    const handleForms = () => {
        setIsNavVisible(true)
        setIsBoatsVisible(false)
    }

    return (
        <>
        <button className="icon-button modal-back-button" onClick={handleForms}>
            <ArrowBackIcon />
        </button>  

        <ul>
            {boats && boats.map((boat, index) => (

                <li 
                    key={index} 
                    onClick={() => setIsBoatsVisible(false)}
                    style={{ borderBottom: 'solid 1px var(--border-color)' }}
                >
                    <NavLink 
                        className="nav-link"
                        to={`boat/${boat.slug}`}
                        style={{ display: 'block', padding: '24px 0' }}
                    >

                        {boat.name}

                    </NavLink>
                </li>

            ))}              

        </ul> 
        </>
    )

}

export default Boats