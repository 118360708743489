export const NEW_TRIP_DETAILS_REQUEST = 'NEW_TRIP_DETAILS_REQUEST'
export const NEW_TRIP_DETAILS_SUCCESS = 'NEW_TRIP_DETAILS_SUCCESS'
export const NEW_TRIP_DETAILS_RESET = 'NEW_TRIP_DETAILS_RESET'
export const NEW_TRIP_DETAILS_FAIL = 'NEW_TRIP_DETAILS_FAIL'

export const UPDATE_TRIP_DETAILS_REQUEST = 'UPDATE_TRIP_DETAILS_REQUEST'
export const UPDATE_TRIP_DETAILS_SUCCESS = 'UPDATE_TRIP_DETAILS_SUCCESS'
export const UPDATE_TRIP_DETAILS_RESET = 'UPDATE_TRIP_DETAILS_RESET'
export const UPDATE_TRIP_DETAILS_FAIL = 'UPDATE_TRIP_DETAILS_FAIL'

export const DELETE_TRIP_DETAILS_REQUEST = 'DELETE_TRIP_DETAILS_REQUEST'
export const DELETE_TRIP_DETAILS_SUCCESS = 'DELETE_TRIP_DETAILS_SUCCESS'
export const DELETE_TRIP_DETAILS_RESET = 'DELETE_TRIP_DETAILS_RESET'
export const DELETE_TRIP_DETAILS_FAIL = 'DELETE_TRIP_DETAILS_FAIL'

export const TRIP_DETAILS_REQUEST = 'TRIP_DETAILS_REQUEST'
export const TRIP_DETAILS_SUCCESS = 'TRIP_DETAILS_SUCCESS'
export const TRIP_DETAILS_FAIL = 'TRIP_DETAILS_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'
