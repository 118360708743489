import { useState      } from 'react'
import { useSelector   } from 'react-redux'
import { validateEmail } from '../../utils'
import { useAlert      } from 'react-alert'
import axios             from 'axios'
import Input             from '../layout/forms/Input'
import CountrySelect     from '../layout/forms/CountrySelect'
import SendIcon          from '../layout/icons/navigation/SendIcon'
import ReCaptcha         from '../layout/tools/reCaptcha/ReCaptcha'

function Contact({ setIsModalVisible }) {

    const alert    = useAlert()

    const { user } = useSelector( state => state.auth ) 

    const [ captcha,   setCaptcha   ] = useState(false) 
    const [ isLoading, setIsLoading ] = useState(false) 
    const [ _user,     setUser      ] = useState({
        firstName:   user?.firstName   || '',
        lastName:    user?.lastName    || '',
        email:       user?.email       || '',
        country:     user?.country     || '',
        countryCode: user?.countryCode || '',
        phone:       user?.phone       || '',
        message: ''
    })   

    const handleObject = (e) => {
        setUser(prev => ({ ...prev, [e.target.name]: e.target.value }))
    }

    const handleCountry = (item, v) => {      
        setUser(prev => ({ ...prev, [item]: v }))    
    }

    const submitHandler = (e) => {
        e.preventDefault()         
        setCaptcha(true)  
    }

    const handleChange = async (value) => {  
        setIsLoading(true)
        const formData = { user: _user, key: value}    
        try {
            await axios.post('/api/v1/contact', formData)
            setCaptcha(false)
            alert.success('Mail sent!')
            setIsModalVisible(false)
        } catch (error) {
            setCaptcha(false)
            alert.error('There was a problem...')
            console.error(error)
        }
    }
    
    return (  
        <>
        {captcha 
        
            ? <ReCaptcha loading={isLoading} handleChange={handleChange} />
               
            : <form onSubmit={submitHandler}>   

                <h3>Contact Us</h3>   

                <div className="d-flex direction-column gap-2">

                        <div className="mobile-parent" style={{ gap: 5 }}>     

                        <Input
                            name="firstName"
                            placeholder="First name *"
                            value={_user.firstName} 
                            onChange={handleObject} 
                            filled={_user.firstName}
                            required
                            autoFocus
                        /> 

                        <Input
                            name="lastName"
                            placeholder="Last name *"
                            value={_user.lastName} 
                            onChange={handleObject} 
                            filled={_user.lastName}
                            required
                        />

                    </div>   

                    <Input
                        name="email"
                        placeholder="Email *"
                        value={_user.email} 
                        onChange={handleObject} 
                        filled={validateEmail(_user.email)}
                        required
                    />

                    <Input
                        name="phone"
                        placeholder="Phone/ WhatsApp *"
                        value={_user.phone} 
                        onChange={handleObject} 
                        filled={_user.phone}
                        required
                    /> 

                    <CountrySelect 
                        country={_user.country}
                        countryCode={_user.countryCode}
                        item="country"
                        onChange={handleCountry}
                        filled={_user.country}
                        required
                    />               

                    <Input 
                        name="message"
                        type="textarea"
                        placeholder="Message *"
                        value={_user.message} 
                        onChange={handleObject}                         
                        rows="10"
                        filled={_user.message}
                        required
                    />

                </div>

                <button                            
                    className="button booking-button gap-1"
                    disabled={ !validateEmail(_user.email) 
                        || !_user.firstName 
                        || !_user.lastName 
                        || !_user.country 
                        || !_user.phone 
                        || !_user.message 
                    }
                >
                    <SendIcon style={{ color: 'white' }} />                       
                    Send Email
                </button>
                        
              </form> 
            
        }
        </>  
    )

}

export default Contact
