import { useState, useEffect            } from 'react'
import { useAlert                       } from 'react-alert'
import { useDispatch, useSelector       } from 'react-redux'
import { clearErrors, register          } from '../../../actions/userActions'
import { isPasswordValid, validateEmail } from '../../../utils'
import Loader                             from '../../layout/tools/Loader'
import ReCaptcha                          from '../../layout/tools/reCaptcha/ReCaptcha'
import Input                              from '../../layout/forms/Input'
import CountrySelect                      from '../../layout/forms/CountrySelect'
import ObjectSelect                       from '../../layout/forms/ObjectSelect'
import LoginIcon                          from '../../layout/icons/login/LoginIcon'
import PersonAddAltIcon                   from '../../layout/icons/people/PersonAddAltIcon'
import AccountCircleIcon                  from '../../layout/icons/people/AccountCircleIcon'

const titleOptions  = [
    { label: 'Mr',  value: 'Mr'  },
    { label: 'Mrs', value: 'Mrs' },
    { label: 'Ms',  value: 'Ms'  },
]

const Register = ({ setIsLoginVisible, setIsRegisterVisible, redirect, setRedirect }) => {

    const alert    = useAlert()
    const dispatch = useDispatch()

    const { loading, isAuthenticated, error } = useSelector( state => state.auth )
   
    const [ captcha,   setCaptcha   ] = useState(false) 
    const [ isLoading, setIsLoading ] = useState(false) 
    const [ avatar,    setAvatar    ] = useState('')
    const [ user,      setUser      ] = useState({ 
        title: '', 
        firstName: '', 
        lastName: '', 
        email: '', 
        phone: '', 
        country: '',
        countryCode: '',
        password: '' 
    })    

    useEffect(() => {        
        if(isAuthenticated) {  
            alert.success(`Registration complete`)   
            if (setRedirect) setRedirect(redirect)  
            setIsRegisterVisible(false)   
            setIsLoading(false)     
            setCaptcha(false)               
        }
        if(error) {  
            alert.error(error)
            dispatch(clearErrors())
            setIsLoading(false)  
            setCaptcha(false) 
        }
    }, [dispatch, setIsRegisterVisible, redirect, setRedirect, alert, user, isAuthenticated, error])
    
    const handleLogin = () => {
        setIsLoginVisible(true)
        setIsRegisterVisible(false)
    }

    const handleObject = (item, value) => {      
        setUser(prev => ({ ...prev, [item]: value }))    
    }

    const onChange = (e) => {
        const { name, value, files } = e.target
        if(name === 'avatar') {
            if (!files || !files[0]) return
            const reader = new FileReader()
            reader.onload = () => {
                if(reader.readyState === 2) {
                    setAvatar(reader.result) 
                }
            }
            reader.readAsDataURL(files[0])
        } else {
            setUser(prev => ({ ...prev, [name]: value }))
        }
    }    

    const submitHandler = (e) => {
        e.preventDefault() 
        setCaptcha(true)  
        window.scroll(0,0)                    
    }    

    const handleChange = (value) => {  
        setIsLoading(true)
        const formData = new FormData()
        formData.set('user', JSON.stringify(user)) 
        formData.set('avatar', avatar)
        formData.set('key', value)        
        dispatch(register(formData))
    }    

    return (  
        <>        
        {captcha 
        
            ? <ReCaptcha loading={isLoading} handleChange={handleChange} />

            : loading ? <Loader /> :  
                
                <form onSubmit={submitHandler}>

                    <h3>Create an Account</h3>  

                    <div className="d-flex direction-column gap-2"> 

                        <div className="d-flex align-items-center gap-2">
                            
                            <label style={{ width: 56, height: 56 }}>                            
                                <input
                                    type="file"  
                                    name="avatar"                            
                                    onChange={onChange}                                                                                                                             
                                />
                                {avatar 
                                    ? <img 
                                        src={avatar} 
                                        alt="Avatar Preview" 
                                        className="round object-fit"
                                      /> 
                                    : <AccountCircleIcon style={{ fontSize: '3.8rem' }} />
                                    
                                }                                                         
                            </label>

                            <ObjectSelect
                                label="Title"
                                item="title"
                                data={titleOptions}
                                value={user.title}                                                                       
                                filled={user.title}
                                onChange={handleObject} 
                                required
                            />

                        </div>
                    
                        <Input 
                            name="firstName"
                            placeholder="First name *"
                            value={user.firstName}                             
                            filled={user.firstName}
                            onChange={onChange}
                            required
                        />

                        <Input 
                            name="lastName"
                            placeholder="Last name *"
                            value={user.lastName}                             
                            filled={user.lastName}
                            onChange={onChange}
                            required
                        />

                        <Input 
                            name="email"
                            placeholder="Email *"
                            value={user.email}                             
                            filled={validateEmail(user.email)}
                            onChange={onChange} 
                            autoCapitalize="none"
                            required
                        /> 

                        <Input 
                            name="phone"
                            placeholder="Phone *"
                            value={user.phone} 
                            filled={user.phone}
                            onChange={onChange}                             
                            required
                        />                         

                        <CountrySelect 
                            item="country"
                            country={user.country}
                            countryCode={user.countryCode}  
                            filled={user.country}
                            onChange={handleObject}
                            required
                        />
                        
                        <Input 
                            type="password"
                            name="password"  
                            placeholder="Password (6 characters min) *"
                            value={user.password}                             
                            onChange={onChange} 
                            filled={isPasswordValid(user.password)}
                            required
                        />  

                    </div>

                    <button                                                   
                        className="button booking-button gap-1"
                        disabled={ !user.title || !user.firstName || !user.lastName || !user.country || !validateEmail(user.email) || !user.phone || !isPasswordValid(user.password) }
                    >
                        <PersonAddAltIcon style={{ color: 'white' }} /> 
                        Sign Up
                    </button>    

                    <div className="d-flex justify-content-between align-items-center">
                        <small>Already signed up?</small> 
                        <small className="d-flex align-items-center gap-1" onClick={handleLogin}>
                            Login                            
                            <button className="icon-button">
                                <LoginIcon />
                            </button> 
                        </small>
                    </div>                
                    
                </form>   

        }           
        </>
    )
    
}

export default Register
