import { useState     } from 'react'
import Modal            from '../../modals/modal/Modal'
import Cart             from '../../modals/merchandise/Cart'
import Shipping         from '../../modals/merchandise/Shipping'
import ConfirmOrder     from '../../modals/merchandise/ConfirmOrder'
import Stripe           from '../../modals/merchandise/Stripe'
import Success          from '../../modals/merchandise/Success'
import ShoppingCartIcon from '../icons/navigation/ShoppingCartIcon'
import AddToCart        from './AddToCart'

const BuyNow = ({ slug, quantity, disabled, query }) => {

    const [ isCartOpen,    setIsCartOpen    ] = useState(false)    
    const [ isShippingOpen, setIsShippingOpen ] = useState(query)    
    const [ isConfirmOpen, setIsConfirmOpen ] = useState(false)    
    const [ isSuccessOpen, setIsSuccessOpen ] = useState(false)       
    const [ isStripeOpen,  setIsStripeOpen  ] = useState(false)   

    const handleCart = () => {
        setIsCartOpen(!isCartOpen)
    }

    const handleShipping = () => {
        setIsShippingOpen(!isShippingOpen)  
     }

    const handleConfirm = () => {
        setIsConfirmOpen(!isConfirmOpen)
    }

    const handleStripe = () => {
        setIsStripeOpen(!isStripeOpen)
    }

    const handleSuccess = () => {
        setIsSuccessOpen(!isSuccessOpen)
    }

    return (        
        <> 
        {slug && quantity ? (
            <AddToCart 
                slug={slug} 
                quantity={quantity} 
                label="Buy now" 
                handleCart={handleCart}
                disabled={disabled}
            />
        ) : (
            <button className="icon-button" aria-label="Cart" onClick={handleCart}>
                <ShoppingCartIcon />
            </button>
        )} 

        <Modal
            isModalVisible={isCartOpen} 
            onBackdropClick={handleCart}   
            content={ 
                <Cart  
                    setIsCartOpen={setIsCartOpen}
                    setIsShippingOpen={setIsShippingOpen}
                /> 
            }
        />

        <Modal
            isModalVisible={isShippingOpen} 
            onBackdropClick={handleShipping}   
            content={ 
                <Shipping  
                    setIsCartOpen={setIsCartOpen}
                    setIsShippingOpen={setIsShippingOpen}
                    setIsConfirmOpen={setIsConfirmOpen}
                /> 
            }
        />

        <Modal
            isModalVisible={isConfirmOpen} 
            onBackdropClick={handleConfirm}   
            content={ 
                <ConfirmOrder  
                    setIsShippingOpen={setIsShippingOpen}
                    setIsConfirmOpen={setIsConfirmOpen}
                    setIsStripeOpen={setIsStripeOpen}
                /> 
            }
        />        

        <Modal
            isModalVisible={isStripeOpen} 
            onBackdropClick={handleStripe}   
            content={ 
                <Stripe                      
                    setIsStripeOpen={setIsStripeOpen}
                    setIsConfirmOpen={setIsConfirmOpen}
                    setIsSuccessOpen={setIsSuccessOpen}
                /> 
            }
        />

        <Modal
            isModalVisible={isSuccessOpen} 
            onBackdropClick={handleSuccess}   
            content={ 
                <Success setIsSuccessOpen={setIsSuccessOpen} /> 
            }
        />
        </>

    )

}

export default BuyNow