import { useState, useEffect         } from 'react'
import { useAlert                    } from 'react-alert'
import { useDispatch, useSelector    } from 'react-redux'
import { UPDATE_PASSWORD_RESET       } from '../../../constants/userConstants'
import { updatePassword, clearErrors } from '../../../actions/userActions'
import { isPasswordValid             } from '../../../utils'
import Loader                          from '../../layout/tools/Loader'
import Input                           from '../../layout/forms/Input'
import HomeIcon                        from '../../layout/icons/navigation/HomeIcon'
import EditOutlinedIcon                from '../../layout/icons/actions/EditOutlinedIcon'

const UpdatePassword = ({ setIsProfileVisible, setIsUpdatePasswordVisible, setIsUpdateProfileVisible }) => {    

    const alert = useAlert() 
    
    const dispatch = useDispatch()      
    
    const { loading, isUpdated, error } = useSelector( state => state.user )

    const [ newPassword, setNewPassword ] = useState('') 
    const [ oldPassword, setOldPassword ] = useState('')      

    useEffect(() => {    
        if(error) { 
            alert.error(error)
            dispatch(clearErrors())
        }
        if(isUpdated) {
            alert.success('Password updated')
            dispatch({ type: UPDATE_PASSWORD_RESET })
        }
    }, [dispatch, alert, error, isUpdated])

    const submitHandler = (e) => {        
        e.preventDefault()
        const formData = new FormData()
        formData.set('oldPassword', oldPassword)
        formData.set('password', newPassword)
        dispatch(updatePassword(formData))
    }

    const handleProfile = () => {
        setIsProfileVisible(true)
        setIsUpdatePasswordVisible(false)
    }

    const handleUpdate = () => {
        setIsUpdateProfileVisible(true)
        setIsUpdatePasswordVisible(false)
    }

    return (
        <>
        {loading ? <Loader /> :  

            <form onSubmit={submitHandler}>

                <h3>Update Password</h3>  

                <div className="d-flex direction-column gap-2">                        

                    <Input 
                        type="password"
                        placeholder="Old Password"
                        value={oldPassword}                        
                        filled={isPasswordValid(oldPassword)}
                        onChange={(e) => setOldPassword(e.target.value)} 
                        required
                        autoFocus
                    /> 

                    <Input 
                        type="password"
                        placeholder="New Password"
                        value={newPassword} 
                        filled={isPasswordValid(newPassword)}
                        onChange={(e) => setNewPassword(e.target.value)}                         
                        required
                    />     

                </div>                    

                <button 
                    className="button booking-button gap-1"
                    disabled={!isPasswordValid(newPassword) || !isPasswordValid(oldPassword)}                      
                >
                    <EditOutlinedIcon />
                    Update Password
                </button>

                <div className="d-flex justify-content-between">                

                    <small className="d-flex align-items-center justify-content-end gap-1">                            
                        <button className="icon-button" onClick={handleUpdate}>
                            <EditOutlinedIcon />
                        </button>
                        Update Profile
                    </small>    

                    <small className="d-flex align-items-center justify-content-end gap-1">                         
                        Profile
                        <button className="icon-button" onClick={handleProfile}>
                            <HomeIcon />
                        </button>
                    </small>    
                                        
                </div>                             

            </form>

        }
        </>
    )

}

export default UpdatePassword
