import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import AirportShuttleIcon from '../../layout/icons/navigation/AirportShuttleIcon'
import KeyboardArrowRightIcon from '../../layout/icons/arrows/KeyboardArrowRightIcon'
import CalendarMonthIcon from '../../layout/icons/navigation/CalendarMonthIcon'
import EditOutlinedIcon from '../../layout/icons/actions/EditOutlinedIcon'
import ShoppingBasketIcon from '../../layout/icons/navigation/ShoppingBasketIcon'
import ScubaDivingIcon from '../../layout/icons/navigation/ScubaDivingIcon'
import SailingIcon from '../../layout/icons/categories/SailingIcon'
import SettingsIcon from '../../layout/icons/navigation/SettingsIcon'
import RestaurantIcon from '../../layout/icons/cabins/RestaurantIcon'

const Navigation = ({ setIsNavVisible, setIsBoatsVisible, setIsCoursesVisible, setIsDestinationsVisible  }) => {

    const { user } = useSelector( state => state.auth ) 

    const handleBoatForms = () => {
        setIsNavVisible(false)
        setIsBoatsVisible(true)
    }

    const handleCourseForms = () => {
        setIsNavVisible(false)
        setIsCoursesVisible(true)
    }

    const handleDiveSiteForms = () => {
        setIsNavVisible(false)
        setIsDestinationsVisible(true)
    }

    return (

        <ul>
            {user && (user.role === 'admin' || user.role === 'agent') && (
                <li onClick={() => setIsNavVisible(false)}>
                    <NavLink 
                        to="trip-list-agent" 
                        className="nav-link"
                    >
                        Agent Area&nbsp;
                        <button className="icon-button" aria-label="Agents">
                            <CalendarMonthIcon />
                        </button>
                    </NavLink>
                </li>
            )}

            <li onClick={() => setIsNavVisible(false)}>
                <NavLink 
                    to="trip-schedules" 
                    className="nav-link"
                >
                    Liveaboard Trips&nbsp;
                    <button className="icon-button" aria-label="Schedules">
                        <SailingIcon />
                    </button>
                </NavLink>
            </li>  

            <li onClick={() => setIsNavVisible(false)}>
                <NavLink 
                    to="trip-itinerary" 
                    className="nav-link"
                >
                    Trip Itinerary&nbsp;
                    <button className="icon-button" aria-label="Itinerary">
                        <ScubaDivingIcon />
                    </button>
                </NavLink>
            </li>  

            <li onClick={() => setIsNavVisible(false)}>
                <NavLink 
                    to="trip-pickups" 
                    className="nav-link"
                >
                    Hotel Transfers&nbsp;
                    <button className="icon-button" aria-label="Hotel Transfers">
                        <AirportShuttleIcon />
                    </button>
                </NavLink>
            </li> 

            <li onClick={() => setIsNavVisible(false)}>
                <NavLink 
                    to="equipment-rental" 
                    className="nav-link"
                >
                    Equipment Rental&nbsp;
                    <button className="icon-button" aria-label="Equipment Rental">
                        <SettingsIcon />
                    </button>
                </NavLink>
            </li> 

            <li onClick={() => setIsNavVisible(false)}>
                <NavLink 
                    to="onboard-dining" 
                    className="nav-link"
                >
                    Onboard Dining&nbsp;
                    <button className="icon-button" aria-label="Onboard dining">
                        <RestaurantIcon />
                    </button>
                </NavLink>
            </li> 

            {/* <li onClick={() => setIsNavVisible(false)}>
                <NavLink to="day-trip-schedule" className="nav-link"
                >
                    Day Trips&nbsp;
                    <button className="icon-button" aria-label="Schedules">
                        <DirectionsBoatIcon />
                    </button>
                </NavLink>
            </li>                     */}

            <li onClick={handleBoatForms} className="cursor-pointer">
                <span className="nav-link">
                    The Boats&nbsp;
                    <button className="cursor-pointer icon-button" aria-label="Boats">
                        <KeyboardArrowRightIcon />
                    </button>    
                </span>   
            </li>

            <li onClick={handleCourseForms} className="cursor-pointer">
                <span className="nav-link">
                    PADI Courses&nbsp;
                    <button className="cursor-pointer icon-button" aria-label="PADI">
                        <KeyboardArrowRightIcon />
                    </button>    
                </span>   
            </li>

            <li onClick={handleDiveSiteForms} className="cursor-pointer">
                <span className="nav-link">
                    Dive Sites&nbsp;
                    <button className="cursor-pointer icon-button" aria-label="Divesites">
                        <KeyboardArrowRightIcon />
                    </button>    
                </span>   
            </li>

            <li onClick={() => setIsNavVisible(false)}>
                <NavLink to="similan-islands-liveaboard-reviews" className="nav-link">
                    Reviews&nbsp;
                    <button className="icon-button" aria-label="Reviews">
                        <EditOutlinedIcon />
                    </button>
                </NavLink>
            </li>

            <li onClick={() => setIsNavVisible(false)}>
                <NavLink to="merchandise" className="nav-link">
                    Merchandise&nbsp;
                    <button className="icon-button" aria-label="Reviews">
                        <ShoppingBasketIcon />
                    </button>
                </NavLink>
            </li>

        </ul>            

    )

}

export default Navigation