export const NEW_FOODPAGE_REQUEST = 'NEW_FOODPAGE_REQUEST'
export const NEW_FOODPAGE_SUCCESS = 'NEW_FOODPAGE_SUCCESS'
export const NEW_FOODPAGE_RESET = 'NEW_FOODPAGE_RESET'
export const NEW_FOODPAGE_FAIL = 'NEW_FOODPAGE_FAIL'

export const UPDATE_FOODPAGE_REQUEST = 'UPDATE_FOODPAGE_REQUEST'
export const UPDATE_FOODPAGE_SUCCESS = 'UPDATE_FOODPAGE_SUCCESS'
export const UPDATE_FOODPAGE_RESET = 'UPDATE_FOODPAGE_RESET'
export const UPDATE_FOODPAGE_FAIL = 'UPDATE_FOODPAGE_FAIL'

export const DELETE_FOODPAGE_REQUEST = 'DELETE_FOODPAGE_REQUEST'
export const DELETE_FOODPAGE_SUCCESS = 'DELETE_FOODPAGE_SUCCESS'
export const DELETE_FOODPAGE_RESET = 'DELETE_FOODPAGE_RESET'
export const DELETE_FOODPAGE_FAIL = 'DELETE_FOODPAGE_FAIL'

export const FOODPAGE_DETAILS_REQUEST = 'FOODPAGE_DETAILS_REQUEST'
export const FOODPAGE_DETAILS_SUCCESS = 'FOODPAGE_DETAILS_SUCCESS'
export const FOODPAGE_DETAILS_FAIL = 'FOODPAGE_DETAILS_FAIL'

export const DELETE_IMAGE_REQUEST = 'DELETE_IMAGE_REQUEST'
export const DELETE_IMAGE_SUCCESS = 'DELETE_IMAGE_SUCCESS'
export const DELETE_IMAGE_RESET = 'DELETE_IMAGE_RESET'
export const DELETE_IMAGE_FAIL = 'DELETE_IMAGE_FAIL'

export const UPDATE_IMAGE_REQUEST = 'UPDATE_IMAGE_REQUEST'
export const UPDATE_IMAGE_SUCCESS = 'UPDATE_IMAGE_SUCCESS'
export const UPDATE_IMAGE_RESET = 'UPDATE_IMAGE_RESET'
export const UPDATE_IMAGE_FAIL = 'UPDATE_IMAGE_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'
