import { useState, useEffect                  } from 'react'
import { useAlert                             } from 'react-alert'
import { useDispatch, useSelector             } from 'react-redux'
import { updateProfile, loadUser, clearErrors } from '../../../actions/userActions'
import { UPDATE_PROFILE_RESET                 } from '../../../constants/userConstants'
import { validateEmail                        } from '../../../utils'
import Loader                                   from '../../layout/tools/Loader'
import Input                                    from '../../layout/forms/Input'
import ObjectSelect                             from '../../layout/forms/ObjectSelect'
import CountrySelect                            from '../../layout/forms/CountrySelect'
import EditOutlinedIcon                         from '../../layout/icons/actions/EditOutlinedIcon'
import HomeIcon                                 from '../../layout/icons/navigation/HomeIcon'
import AccountCircleIcon                        from '../../layout/icons/people/AccountCircleIcon'

const titleOptions  = [
    { label: 'Mr',  value: 'Mr'  },
    { label: 'Mrs', value: 'Mrs' },
    { label: 'Ms',  value: 'Ms'  },
]

const UpdateProfile = ({ setIsUpdateProfileVisible, setIsProfileVisible, setIsUpdatePasswordVisible }) => {

    const alert    = useAlert()    
    const dispatch = useDispatch()

    const { user                      } = useSelector( state => state.auth )
    const { loading, isUpdated, error } = useSelector( state => state.user )

    const [ avatar, setAvatar ] = useState('')
    const [ _user,  setUser   ] = useState({ 
        title: '', 
        firstName: '', 
        lastName: '', 
        email: '', 
        phone: '', 
        country: '',
        countryCode: '',
    })
    
    useEffect(() => {
        if(user) {       
            setUser(user) 
            if (user.avatar) {
                setAvatar(user.avatar.url) 
            } 
        }
        if(error) { 
            alert.error(error)
            dispatch(clearErrors())
        }
        if(isUpdated) {
            alert.success('User updated')
            dispatch(loadUser())
            dispatch({ type: UPDATE_PROFILE_RESET })
        }
    }, [dispatch, user, alert, isUpdated, error])

    const handleProfile = () => {
        setIsProfileVisible(true)
        setIsUpdateProfileVisible(false)
    }

    const handleUpdate = () => {
        setIsUpdateProfileVisible(false)
        setIsUpdatePasswordVisible(true)
    }

    const handleObject = (item, value) => {      
        setUser(prev => ({ ...prev, [item]: value }))    
    } 

    const onChange = (e) => {
        const { name, value, files } = e.target
        if(name === 'avatar') {
            if (!files || !files[0]) return
            const reader = new FileReader()
            reader.onload = () => {
                if(reader.readyState === 2) {
                    setAvatar(reader.result)    
                }
            }
            reader.readAsDataURL(files[0])
        } else {
            setUser(prev => ({ ...prev, [name]: value }))
        }
    }    

    const submitHandler = (e) => {        
        e.preventDefault()
        const formData = new FormData()
        formData.set('user', JSON.stringify(_user)) 
        formData.set('avatar', avatar)
        dispatch(updateProfile(formData))
    } 

    return (
        <>  
        {loading ? <Loader /> : ( 
            
            <form onSubmit={submitHandler}>
                
                <h3>Update Profile</h3>                

                <div className="d-flex direction-column gap-2">
                   
                    <div className="d-flex align-items-center gap-2"> 

                        <label style={{ width: 56, height: 56 }}>                            
                            <input
                                type="file"  
                                name="avatar"                            
                                onChange={onChange}                                                                                                                             
                            />                                                        
                            {avatar 
                                ? <img 
                                    src={avatar} 
                                    alt="Avatar Preview" 
                                    className="round object-fit"
                                  /> 
                                :  <AccountCircleIcon style={{ fontSize: '3.8rem' }} />
                            }   
                        </label>                        

                        <ObjectSelect
                            label="Title"
                            item="title"
                            data={titleOptions}
                            value={_user.title}
                            filled={_user.title}
                            onChange={handleObject} 
                            required
                        />                       

                    </div>   
                        
                    <Input 
                        name="firstName"
                        placeholder="First name *"
                        value={_user.firstName} 
                        filled={_user.firstName}
                        onChange={onChange}                        
                        required
                    />

                    <Input 
                        name="lastName"
                        placeholder="Last name *"
                        value={_user.lastName} 
                        filled={_user.lastName}
                        onChange={onChange}                        
                        required
                    />

                    <Input 
                        name="email"
                        placeholder="Email *"
                        value={_user.email}                         
                        filled={validateEmail(_user.email)}
                        onChange={onChange} 
                        autoCapitalize="none"
                        required
                    /> 

                    <Input 
                        name="phone"
                        placeholder="Phone *"
                        value={_user.phone}                         
                        filled={_user.phone}
                        onChange={onChange} 
                        required
                    />  

                    <CountrySelect 
                        item="country"
                        country={_user.country}
                        countryCode={_user.countryCode}
                        filled={_user.country}
                        onChange={handleObject}                        
                        required
                    />   

                </div>                                                        

                <button
                    className="button booking-button gap-1"
                    disabled={ !_user.title || !_user.firstName || !_user.lastName || !_user.country || !validateEmail(_user.email) || !_user.phone }
                >
                    <EditOutlinedIcon />
                    Update
                </button>   

                <div className="d-flex justify-content-between"> 
                    
                    <small className="d-flex align-items-center justify-content-end gap-1">                            
                        <button className="icon-button" onClick={handleUpdate}>
                            <EditOutlinedIcon />
                        </button>                        
                        Update Password
                    </small>   

                    <small className="d-flex align-items-center justify-content-end gap-1">    
                        Profile
                        <button className="icon-button" onClick={handleProfile}>
                            <HomeIcon />
                        </button>                       
                    </small>                     
                                                                        
                </div>                            

            </form>
        )}
        </>
    )

}

export default UpdateProfile
