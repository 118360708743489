import {    
    NEW_FOODPAGE_REQUEST,
    NEW_FOODPAGE_RESET,
    NEW_FOODPAGE_SUCCESS,
    NEW_FOODPAGE_FAIL,
    UPDATE_FOODPAGE_REQUEST,
    UPDATE_FOODPAGE_SUCCESS,
    UPDATE_FOODPAGE_RESET,
    UPDATE_FOODPAGE_FAIL,
    DELETE_FOODPAGE_REQUEST,
    DELETE_FOODPAGE_SUCCESS,
    DELETE_FOODPAGE_RESET,
    DELETE_FOODPAGE_FAIL,   
    FOODPAGE_DETAILS_REQUEST,
    FOODPAGE_DETAILS_SUCCESS,
    FOODPAGE_DETAILS_FAIL,   
    UPDATE_IMAGE_REQUEST,
    UPDATE_IMAGE_SUCCESS,
    UPDATE_IMAGE_RESET,
    UPDATE_IMAGE_FAIL, 
    DELETE_IMAGE_REQUEST,
    DELETE_IMAGE_SUCCESS,
    DELETE_IMAGE_RESET,
    DELETE_IMAGE_FAIL,
    CLEAR_ERRORS 
} from '../constants/foodpageConstants'

export const newFoodpageReducer = ( state = { foodpage: {} }, action ) => {
    switch (action.type) {

        case NEW_FOODPAGE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case NEW_FOODPAGE_SUCCESS:
            return {
                loading: false,
                success: action.payload.success,
                foodpage: action.payload.foodpage
            }
        case NEW_FOODPAGE_FAIL:
                return {
                    ...state,
                    error: action.payload,
                    loading: false,
                }
        case NEW_FOODPAGE_RESET:
            return {
                ...state,
                success: false
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const foodpageReducer = ( state = {}, action ) => {
    switch (action.type) { 
        case UPDATE_IMAGE_REQUEST:  
        case DELETE_IMAGE_REQUEST:   
        case DELETE_FOODPAGE_REQUEST:
        case UPDATE_FOODPAGE_REQUEST:
            return {
                ...state,
                loading: true
            } 
        case DELETE_IMAGE_SUCCESS: 
        case DELETE_FOODPAGE_SUCCESS:        
            return {
                ...state,
                loading: false,
                isDeleted: action.payload
            }  
        case UPDATE_IMAGE_SUCCESS:
        case UPDATE_FOODPAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                isUpdated: action.payload
            }  
        case DELETE_IMAGE_FAIL:
        case UPDATE_IMAGE_FAIL: 
        case DELETE_FOODPAGE_FAIL:
        case UPDATE_FOODPAGE_FAIL:
            return {
                ...state,
                error: action.payload
            }  
        case DELETE_IMAGE_RESET:
        case UPDATE_IMAGE_RESET:      
        case DELETE_FOODPAGE_RESET:
            return {
                ...state,
                isDeleted: false
            }        
        case UPDATE_FOODPAGE_RESET:
            return {
                ...state,
                isUpdated: false
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const foodpageDetailsReducer = ( state = { foodpage: {} }, action ) => {
    switch (action.type) {
        case FOODPAGE_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FOODPAGE_DETAILS_SUCCESS:
            return {
                loading: false,
                foodpage: action.payload
            }
        case FOODPAGE_DETAILS_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state
    }
}
