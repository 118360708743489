import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../../actions/userActions'
import { useAlert } from 'react-alert'
import { formatMyDate } from '../../../utils'
import Loader from '../../layout/tools/Loader'
import PhoneIcon from '../../layout/icons/navigation/PhoneIcon'
import EditOutlinedIcon from '../../layout/icons/actions/EditOutlinedIcon'
import LogoutIcon from '../../layout/icons/login/LogoutIcon'
import DirectionsBoatIcon from '../../layout/icons/navigation/DirectionsBoatIcon'
import ShoppingCartIcon from '../../layout/icons/navigation/ShoppingCartIcon'
import AccessTimeIcon from '../../layout/icons/navigation/AccessTimeIcon'
import PassportIcon from '../../layout/icons/travel/PassportIcon'
import SendIcon from '../../layout/icons/navigation/SendIcon'
import AccountCircleIcon from '../../layout/icons/people/AccountCircleIcon'

const Profile = ({ setIsProfileVisible, setIsUpdatePasswordVisible, setIsUpdateProfileVisible }) => {

    const alert    = useAlert()
    const dispatch = useDispatch()

    const { user, loading } = useSelector( state => state.auth )   

    const logoutHandler = () => {
        dispatch(logout())
        alert.success('Logged out')
        setIsProfileVisible(false)
    }

    const handleUpdateProfile = () => {
        setIsProfileVisible(false)
        setIsUpdateProfileVisible(true)
    }

    const handleUpdatePassword = () => {
        setIsProfileVisible(false)
        setIsUpdatePasswordVisible(true)
    }
   
    return (
        <>
        {loading ? <Loader /> :  
            <>
            <h3>{user.firstName}'s Account</h3>    

            <div className="parent align-items-center gap-4">

                {user.avatar 
                    ? <figure 
                        className="d-flex align-items-center justify-content-center"
                        style={{ width: '159px', height: '159px' }}
                        >
                        <img 
                            src={user.avatar.url} 
                            alt={user.name} 
                            className="object-fit round"
                        /> 
                        </figure>
                    : <div style={{ width: '159px', height: '159px' }}>
                        <AccountCircleIcon style={{ fontSize: '8rem' }} />
                        </div>
                }                    

                <div 
                    className="d-flex justify-content-between direction-column"
                    style={{ flex: 1, textAlign: 'right'}}
                >                         
                    <small className="d-flex justify-content-end align-items-center gap-1">
                        <b>Name</b>
                        <PassportIcon style={{ fontSize: '1rem' }} />
                    </small>   
                    <p>{user.title} {user.firstName} {user.lastName} <small>({user.role})</small></p>
                    
                    <small className="d-flex justify-content-end align-items-center gap-1">
                        <b>Email</b>
                        <SendIcon style={{ fontSize: '1rem' }} />
                    </small>
                    <p>{user.email}</p>
                    
                    <small className="d-flex justify-content-end align-items-center gap-1">
                        <b>Phone</b>
                        <PhoneIcon style={{ fontSize: '1rem' }} />
                    </small>                        
                    <p>
                        {user.country &&
                            <img
                                alt={user.country} 
                                src={`https://flagcdn.com/${user.countryCode}.svg`}
                                style={{ width: '20px', marginRight: '10px' }}
                            />
                        }                            
                        {user.phone}
                    </p>

                    <small className="d-flex justify-content-end align-items-center gap-1">
                        <b>Joined</b>
                        <AccessTimeIcon style={{ fontSize: '1rem' }} />                            
                    </small>  
                    <p>{formatMyDate(user.createdAt)}</p>
                </div>
                
            </div>                

            <div className="d-flex justify-content-between gap-2" style={{ marginTop: 40 }}>
                
                <div>                          

                    <Link to="/bookings/me" onClick={() => setIsProfileVisible(false)}>
                        <small className="d-flex align-items-center">
                            <button className="icon-button">
                                <DirectionsBoatIcon />
                            </button>
                            &nbsp; My Bookings
                        </small>
                        
                    </Link>   

                    <br />

                    <Link to="/orders/me" onClick={() => setIsProfileVisible(false)}>
                        <small className="d-flex align-items-center">
                            <button className="icon-button">
                                <ShoppingCartIcon />
                            </button>
                            &nbsp; My Orders
                        </small>
                        
                    </Link>   

                    <br />

                    <small className="d-flex align-items-center">                            
                        <button className="icon-button" onClick={logoutHandler}>
                            <LogoutIcon />
                        </button>
                        &nbsp; Logout  
                    </small>      

                </div>                                 

                <div>   
                    <small className="d-flex align-items-center justify-content-end">
                        Update Profile  &nbsp; 
                        <button className="icon-button" onClick={handleUpdateProfile}>
                            <EditOutlinedIcon />
                        </button>
                    </small>
                    <br />                        
                    <small className="d-flex align-items-center justify-content-end">
                        Update Password  &nbsp; 
                        <button className="icon-button" onClick={handleUpdatePassword}>
                            <EditOutlinedIcon />
                        </button>
                    </small>   
                </div> 

            </div>  
            </>    
        }            
        </>
    )

}

export default Profile
