import { useState } from 'react'
import Backdrop from '../tools/Backdrop'
import ArrowDropDownIcon from '../icons/arrows/ArrowDropDownIcon'
import ArrowDropUpIcon from '../icons/arrows/ArrowDropUpIcon'
import EditOutlinedIcon from '../icons/actions/EditOutlinedIcon'
import DeleteOutlineIcon from '../icons/actions/DeleteOutlineIcon'
import './forms.css'

function ObjectSelect({ type, category, item, style, className, label, data, value, onChange, onChangeTwo, onChangeThree, func, filled, required, bottom, disabled, updateCatOpen, setUpdateCatOpen, setId, confirmOpen, setConfirmOpen }) {  

    const [ isOpen, setIsOpen ] = useState(false)  

    const handleSelectChange = (e) => {
        if (onChange) {            
            onChange(item, e.target.value)           
            if (func) func(e)
        }  
        if (onChangeThree) {
            onChangeThree(category, item, e.target.value)
        }       
        setIsOpen(false)   
    }   
    
    const handleMultiSelectChange = (e) => {
        onChangeTwo(e.target[0].value, e.target[1].value)   
        setIsOpen(false)    
    }

    const toggleDropdown = () => {
        if (!disabled) setIsOpen(!isOpen)        
    }  

    return (
        <>
        {isOpen && (
            <Backdrop
                invisible
                onClick={toggleDropdown}
            /> 
        )}

        <div style={{ cursor: disabled ? 'not-allowed' : 'pointer', flex: 1, width: '100%'}}>
        
            <div 
                className={`select-container relative`}
                style={{ pointerEvents: disabled ? 'none' : 'auto' }}
            >
                <div 
                    className={`label ${isOpen ? 'open' : ''}`} 
                    onClick={toggleDropdown}  
                >
                    <div 
                        tabIndex={0} 
                        className={`cursor-pointer input focusable-div ${className ? className : null}`}
                        style={required 
                            ? { 
                            background: filled 
                                ? 'var(--cta-discount)' 
                                : required
                                    ? 'var(--cta-required)'
                                    : 'transparent'
                            } : style}
                    >

                        <div className="d-flex align-items-center">                        
                            {value || value === 0 ? value : label}
                        </div>
                        
                    </div>               

                    <small>{label}</small>
                    
                    <span className="adornment-end">                   
                        {isOpen 
                            ? <ArrowDropUpIcon style={{ color: 'var(--cta-red)' }} />
                            : <ArrowDropDownIcon style={{ color: 'var(--cta-green)' }} />}                                                        
                    </span>

                </div>

                {isOpen && !onChangeTwo && (                    
                    <ul className={`select-dropdown ${bottom ?  'bottom' : null}`}>
                        {data && data.map((item, index) => (
                            <li 
                                key={index} 
                                onClick={() => handleSelectChange({ target: { value: item.value } })}
                            >
                                {item.label}
                            </li>
                        ))}
                    </ul>
                )}

                {isOpen && onChangeTwo && type !== 'category' && (   
                    <ul className="select-dropdown">
                        {data && data.map((item, index) => (
                            <li 
                                key={index} 
                                onClick={() => handleMultiSelectChange({ target: [{ value: item.value }, { value: item.id }] })}
                            >
                                {item.label}
                            </li>
                        ))}
                    </ul>
                )}

                {isOpen && type === 'category' && (
                    <ul className="select-dropdown">
                        {data && data.map((item, index) => (
                            <li 
                                key={index} 
                                className="d-flex align-items-center justify-content-between"                                
                            >
                                <span 
                                    className="select-label"
                                    onClick={() => handleSelectChange({ target: { value: item.value } })}
                                >
                                    {item.label}
                                </span>
                                <span className="d-flex">
                                    <span 
                                        className="icon-button"
                                        onClick={() => {
                                            setUpdateCatOpen(!updateCatOpen)
                                            setId(item.id)
                                        }}
                                    >
                                        <EditOutlinedIcon />
                                    </span>
                                    &nbsp;
                                    <span 
                                        className="icon-button"
                                        onClick={() => {
                                            setConfirmOpen(!confirmOpen)
                                            setId(item.id)
                                        }}
                                    >
                                        <DeleteOutlineIcon />
                                    </span>
                                </span>
                            </li>
                        ))}
                    </ul>
                )}
                
            </div>      

        </div> 
        </>
    )
}

export default ObjectSelect
