import { useState, useEffect         } from 'react'
import { useDispatch, useSelector    } from 'react-redux'
import { useAlert                    } from 'react-alert'
import { forgotPassword, clearErrors } from '../../../actions/userActions'
import { validateEmail               } from '../../../utils'
import Loader                          from '../../layout/tools/Loader'
import ReCaptcha                       from '../../layout/tools/reCaptcha/ReCaptcha'
import Input                           from '../../layout/forms/Input'
import LoginIcon                       from '../../layout/icons/login/LoginIcon'
import KeyIcon                         from '../../layout/icons/login/KeyIcon'

const ForgotPassword = ({ setIsLoginVisible, setIsForgotVisible }) => {

    const { loading, message, error } = useSelector( state => state.forgotPassword )

    const alert    = useAlert()
    const dispatch = useDispatch()
    
    const [ email,     setEmail     ] = useState('')    
    const [ captcha,   setCaptcha   ] = useState(false)
    const [ isLoading, setIsLoading ] = useState(false) 

    useEffect(() => {  
        if(message) {
            alert.success(message)   
            setIsForgotVisible(false) 
            setIsLoading(false)     
            setCaptcha(false) 
        } 
        if(error) { 
            alert.error(error)
            dispatch(clearErrors())   
            setIsLoading(false)  
            setCaptcha(false)          
        }       
    }, [dispatch, setIsForgotVisible, alert, message, error])   

    const submitHandler = (e) => {
        e.preventDefault()  
        setCaptcha(true)  
        window.scroll(0,0)  
    }

    const handleLogin = () => {
        setIsLoginVisible(true)
        setIsForgotVisible(false)
    }

    const handleChange = (value) => {  
        setIsLoading(true)
        const formData = new FormData()
        formData.set('email', email)
        formData.set('key', value)   
        dispatch(forgotPassword(formData))
    }   

    return (
        <>  
        {captcha 
        
            ? <ReCaptcha loading={isLoading} handleChange={handleChange} />

            : loading ? <Loader /> :                             

                <form onSubmit={submitHandler}>

                    <h3>Forgot Password</h3> 

                    <Input 
                        placeholder="Email"
                        value={email} 
                        onChange={(e) => setEmail(e.target.value)} 
                        filled={validateEmail(email)}
                        autoCapitalize="none"
                        required
                        autoFocus
                    />   

                    <button 
                        type="submit"                             
                        className="button booking-button gap-1"
                        disabled={!validateEmail(email)}
                    >
                        <KeyIcon style={{ color: 'white' }} /> 
                        Request New Password
                    </button>                          

                    <div className="d-flex justify-content-between align-items-center">
                        <small>Already signed up?</small>
                        <small className="d-flex align-items-center gap-1" onClick={handleLogin}>
                            Login
                            <button className="icon-button">
                                <LoginIcon />
                            </button>
                        </small>                        
                    </div>                       

                </form>
            
        }   
        </>
    )

}

export default ForgotPassword
