import { NavLink } from "react-router-dom"
import ArrowBackIcon from "../../layout/icons/arrows/ArrowBackIcon"

const Courses = ({ setIsNavVisible, setIsCoursesVisible, courses }) => {

    const handleForms = () => {
        setIsNavVisible(true)
        setIsCoursesVisible(false)
    }

    return (
        <>
        <button className="icon-button modal-back-button"onClick={handleForms}>
            <ArrowBackIcon />
        </button> 

        <ul>
            {courses && courses.map((course, index) => (

                <li 
                    key={index} 
                    onClick={() => setIsCoursesVisible(false)}
                >
                    <NavLink 
                        className="nav-link"
                        to={`course/${course.slug}`}
                        style={{ display: 'block', padding: '24px 0' }}
                    >

                        {course.name}

                    </NavLink>
                </li>

            ))}              

        </ul> 
        </>
    )

}

export default Courses