import ReCAPTCHA      from 'react-google-recaptcha'
import LinearProgress from '../../forms/LinearProgress'
import './recaptcha.css'

const ReCaptcha = ({ loading, handleChange }) => {

    const key = process.env.REACT_APP_NODE_ENV === 'PRODUCTION'
        ? process.env.REACT_APP_RECAPTCHA_SITE_KEY
        : process.env.REACT_APP_RECAPTCHA_SITE_TEST_KEY

    return (     

        <div className="recaptcha">

            <h3>Security Check</h3>

            <ReCAPTCHA sitekey={key}onChange={handleChange}/>

            {loading && <LinearProgress />}

        </div>

    )

}

export default ReCaptcha