import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { addMerchItemToCart, removeMerchItemFromCart, emptyMerchCart } from '../../../actions/cartActions'
import { formatPrice } from '../../../utils'
import AddIcon from '../../layout/icons/actions/AddIcon'
import DeleteOutlineIcon from '../../layout/icons/actions/DeleteOutlineIcon'
import RemoveIcon from '../../layout/icons/actions/RemoveIcon'
import DeleteForeverIcon from '../../layout/icons/actions/DeleteForeverIcon'
import ToolTip from '../../layout/tools/tooltip/ToolTip'
import ProductionQuantityLimitsIcon from '../../layout/icons/navigation/ProductionQuantityLimitsIcon'
import GlobeIcon from '../../layout/icons/navigation/GlobeIcon'
import LoginIcon from '../../layout/icons/login/LoginIcon'
import PersonAddAltIcon from '../../layout/icons/people/PersonAddAltIcon'

const Cart = ({ setIsCartOpen, setIsShippingOpen }) => {    

    const dispatch = useDispatch()
    const location = useLocation()

    const { merchCartItems: cartItems } = useSelector( state => state.merchCart )
    const { user } = useSelector( state => state.auth )  

    let totalPrice = cartItems.reduce((acc, item) => acc + item.quantity * item.price, 0)

    const calcSubTotal = () => {
        let subTotal = 0       
        cartItems.forEach((item) => {
            subTotal += item.price * item.quantity          
        })  
        return subTotal
    }

    const calculateTotalShipping = (cartItems) => {
        let shippingCost = 0      
        cartItems.forEach((item) => {
          if (item.quantity === 1) {
            shippingCost += item.shipping
          } else {
            shippingCost += item.shipping + (item.shippingTwo * (item.quantity - 1))
          }
        })      
        return shippingCost
    }

    const shipping = calculateTotalShipping(cartItems)
    const subTotal = calcSubTotal()
      
    const removeCartItemHandler = (id) => {
        dispatch(removeMerchItemFromCart(id))
    }
    const emptyCartHandler = () => {   
        dispatch(emptyMerchCart())
    }
    const increaseQty = (id, quantity, stock) => {
        const newQty = quantity + 1
        if(newQty > stock) { return }
        dispatch(addMerchItemToCart(id, newQty))
    }
    const decreaseQty = (id, quantity) => {
        const newQty = quantity - 1
        if(newQty <= 0) { return }
        dispatch(addMerchItemToCart(id, newQty))
    }    
    const checkoutHandler = () => {    
        setIsCartOpen(false)  
        setIsShippingOpen(true)         
    }

    return (        
        <>
        <div className="relative">    

            <h3>Shopping Cart</h3>         

            {cartItems.length === 0 

                ?   <div className="d-flex align-items-center justify-content-center direction-column">
                        
                        <ProductionQuantityLimitsIcon style={{fontSize: '5rem'}} />     

                        <p style={{ marginTop: 40 }}>Shopping Cart is empty</p>  

                    </div>                     
                    
                : 
                    <>
                    <table className="cart-table">
                        <tbody>
                            <tr>  
                                <th 
                                    colSpan="3" 
                                    style={{ background: 'none', paddingTop: 0 }}
                                    className="text-right"
                                >
                                    <ToolTip title="Empty cart">
                                        <button 
                                            className="icon-button" 
                                            onClick={emptyCartHandler}  
                                        >
                                            <DeleteForeverIcon style={{ color: 'red' }} />
                                        </button>
                                    </ToolTip>
                                </th>                                
                            </tr>
                            {cartItems && cartItems.map((item, index) => (
                                <tr key={index}>
                                    <th style={{ width: 150 }}  className="text-center relative">
                                        <Link to={`/product/${item.slug}`}>
                                            <img
                                                src={item.image} 
                                                alt={item.name} 
                                                style={{ width: 50 }}
                                            />
                                            <br />   
                                            <b><small>{item.name}</small></b>
                                        </Link>

                                        <br />

                                        {formatPrice(item.price)}
                                        <span style={{ position: 'absolute', top: 5, right: 5 }}>
                                            <ToolTip title="Remove">
                                                <button onClick={() => removeCartItemHandler(item.merch)}>
                                                    <DeleteOutlineIcon />
                                                </button>  
                                            </ToolTip>  
                                        </span>
                                    </th>
                                    <td>
                                        <div className="d-flex justify-content-end" style={{ gap:5 }}>                                 

                                            <ToolTip title="Decrease">
                                                <button 
                                                    className={item.quantity <= 1 
                                                        ? 'icon-button pointer-events-none' 
                                                        : 'icon-button'
                                                    } 
                                                    onClick={() => decreaseQty(item.slug, item.quantity)}
                                                >
                                                    <RemoveIcon className={item.quantity <= 1 ? 'danger ' : ''} />
                                                </button>
                                            </ToolTip>

                                            <input 
                                                className="text-center"
                                                value={item.quantity} 
                                                style={{ color: 'var(--icon-color)', width: '40px' }}
                                                readOnly 
                                            /> 
                                            
                                            <ToolTip title="Increase">
                                                <button 
                                                    className={item.quantity === item.stock 
                                                        ? 'icon-button pointer-events-none' 
                                                        : 'icon-button'
                                                    } 
                                                    onClick={() => increaseQty(item.slug, item.quantity, item.stock)}
                                                >
                                                    <AddIcon />
                                                </button>       
                                            </ToolTip>   
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>                              

                    <h3>Summary</h3>
                    
                    <table className="cart-table">
                    <tbody>
                        <tr>
                            <th className="text-right" style={{ width: 150 }}>
                                Units
                            </th>                            
                            <td className="text-right">
                                {cartItems.reduce((acc, item) => (acc + Number(item.quantity)), 0)} 
                            </td>
                        </tr>

                        <tr>
                            <th className="text-right">Sub total</th>
                            <td className="text-right">
                                {formatPrice(subTotal)}
                            </td>
                        </tr>

                        <tr>
                            <th className="text-right">Shipping</th>
                            <td className="text-right">
                                {formatPrice(shipping)}
                            </td>
                        </tr>

                        <tr>
                            <th className="text-right">Total</th>
                            <td className="text-right">
                                {formatPrice(totalPrice + shipping)}
                            </td>
                        </tr>
                    </tbody>
                    </table>  

                    {user ? (
                        <div className="d-flex gap-2 justify-content-center">
                            <button
                                className="button booking-button"
                                onClick={checkoutHandler}
                                style={{ marginTop: 40, width: 160, justifyContent: 'flex-start' }}
                            >
                                <GlobeIcon style={{ color: 'white' }} />
                                &nbsp;
                                Shipping
                            </button>
                        </div>
                    ) : (
                        <div 
                            className="d-flex direction-column gap-2 align-items-center"
                            style={{ marginTop: 40 }}
                        >
                            <Link to={`/login?redirect=${location.pathname}&isCartOpen=true`}>
                                <button 
                                    className="button booking-button" 
                                    onClick={() => setIsCartOpen(false)}   
                                    style={{ width: 230, justifyContent: 'flex-start' }}                                 
                                >
                                    <LoginIcon style={{ color: 'white' }} /> &nbsp; Login to continue
                                </button>
                            </Link>

                            <Link to={`/register?redirect=${location.pathname}&isCartOpen=true`}>
                                <button
                                    className="button booking-button"
                                    onClick={() => setIsCartOpen(false)}    
                                    style={{ width: 230, justifyContent: 'flex-start' }}                                   
                                >
                                    <PersonAddAltIcon style={{ color: 'white' }} /> &nbsp; Register an account
                                </button>
                            </Link>
                        </div>
                    )}
                    </>
                } 
            
        </div>
        </>
    )

}

export default Cart